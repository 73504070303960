import React, { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import settingsIcon from '../settings-icon.png';
import rewindIcon from '../rewind-10-icon.png';
import forwardIcon from '../forward-10-icon.png';
import TimelineSettingsDialog from './TimelineSettingsDialog';
import deathIcon from '../death-skull-icon.png';
import '../styles/VideoPlayer.css'


function VideoPlayer({
  currentVod,
  currentTimestamp,
  setCurrentTimestamp,
  currentEncounterStartTime,
  currentEncounterDuration,
  setCurrentOffset,
  currentOffset,
  deathCutoff,
  setDeathCutoff,
  encounterEvents,
  currentLog,
  setEncounterEvents,
  WCLToken,
  uniqueBossEvents,
  setUniqueBossEvents,
  allBossEvents,
  setAllBossEvents,
  currentEncounter,
  loadingLog,
}) {

  const playerRef = useRef(null);
  const dialogRef = useRef(null);
  const buttonRef = useRef(null);
  const [isSeeking, setIsSeeking] = useState(false);
  const [currentTimelineTime, setCurrentTimelineTime] = useState(currentTimestamp);
  const [tooltipTime, setTooltipTime] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tempCurrentTimelineTime, setTempCurrentTimelineTime] = useState(null);
  const [showTimelineSettings, setShowTimelineSettings] = useState(false);
  const [encounterEventsVisibility, setEncounterEventsVisibility] = useState();
  const [highlightTimestamps, setHighlightTimestamps] = useState({});
  const [showTimelineLine, setShowTimelineLine] = useState(false);


  useEffect(() => {
    if (playerRef.current && currentVod) {
      playerRef.current.loadVideoById({
        videoId: currentVod.id,
        startSeconds: currentTimestamp,
      });
    }
  }, [currentVod, currentTimestamp]);

  useEffect(() => {
    if (currentEncounter) {
      setAllBossEvents({})
      setUniqueBossEvents({})
      setEncounterEventsVisibility({})
      setTempCurrentTimelineTime(null)
      setCurrentTimelineTime(currentTimestamp)
    }
  }, [currentEncounter]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (!isSeeking && playerRef.current && playerRef.current.getCurrentTime) {
        if (tempCurrentTimelineTime) {
          const currentTime = playerRef.current.getCurrentTime();
          setCurrentTimelineTime(Math.max((tempCurrentTimelineTime + (currentTime - currentTimestamp)), 0));
        }
        else {
          const currentTime = playerRef.current.getCurrentTime();
          setCurrentTimelineTime(Math.max(currentTime - currentTimestamp, 0));
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isSeeking, currentTimestamp]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowTimelineSettings(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (encounterEvents && Object.keys(encounterEvents).length > 0) {
      let tempHighlightTimestamps = highlightTimestamps;
      tempHighlightTimestamps = Object.keys(encounterEvents.events).map(e => {

        if (e == 'deaths') {
          const tempTempHighlightTimestamps = encounterEvents.events.deaths.map(event => {
            return event.timestamp / 1000;
          }).slice(0, deathCutoff);
          setHighlightTimestamps({ ...highlightTimestamps, [e.toString()]: tempTempHighlightTimestamps });
        }
        else {
          const tempTempHighlightTimestamps = encounterEvents.events[e].map(event => {
            return event.timestamp / 1000;
          })
          setHighlightTimestamps({ ...highlightTimestamps, [e.toString()]: tempTempHighlightTimestamps });
        }

      })
    }
  }, [encounterEvents]);

  const handleStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING && isSeeking) {
      setIsSeeking(false);
    }
    if (event.data === window.YT.PlayerState.PLAYING && tempCurrentTimelineTime) {
      setCurrentTimelineTime(tempCurrentTimelineTime);
    }
  };

  const handleSeeking = () => {
    setIsSeeking(true);
  };

  const handlePlayerReady = (event) => {
    playerRef.current = event.target;
    event.target.setPlaybackQuality('hd720');
  };

  const handleTimelineHover = (event) => {
    setShowTimelineLine(true);
    const timelineRect = event.currentTarget.getBoundingClientRect();
    const timelineWidth = timelineRect.width;
    const timelineLeft = timelineRect.left;
    const mousePosition = event.clientX - timelineLeft;
    const time = (mousePosition / timelineWidth) * currentEncounterDuration;
    setTooltipTime(time);
    setShowTooltip(true);
    setTooltipPosition(event.clientX - timelineLeft);
  };


  const handleTimelineLeave = () => {
    setShowTooltip(false);
    setShowTimelineLine(false);
  };

  const handleTimelineClick = (event) => {
    const { left, width } = event.currentTarget.getBoundingClientRect();
    const mousePosition = event.clientX - left;
    const timelineWidth = width;
    const time = (currentTimestamp + (mousePosition / timelineWidth) * currentEncounterDuration) - (currentTimestamp - currentEncounterStartTime);
    playerRef.current.seekTo(time);
    setCurrentTimestamp(time)
    setTempCurrentTimelineTime(tooltipTime)

  };

  function formattedCurrentTime() {
    const minutes = Math.floor(currentTimelineTime / 60);
    const seconds = Math.floor(currentTimelineTime % 60);
    const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    return formattedTime;
  }

  function formattedTooltipTime() {
    if (tooltipTime == null) {
      return '';
    }
    const minutes = Math.floor(tooltipTime / 60);
    const seconds = Math.floor(tooltipTime % 60);
    const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    return formattedTime;
  }

  function skipButton(skip) {
    if (tempCurrentTimelineTime) {
      setTempCurrentTimelineTime(Math.max(tempCurrentTimelineTime + skip, 0))
      setCurrentTimestamp(currentTimestamp + skip)
    }
    else {
      let time = Math.max(currentTimelineTime + skip, 0)
      setCurrentTimelineTime(time)
      setCurrentTimestamp(currentTimestamp + skip)
      setTempCurrentTimelineTime(time)
    }
  }

  function adjustOffsetConfirm() {
    const inputField = document.getElementById('tsdoi1');
    setCurrentOffset(document.getElementById('tsdoi1').value);
    inputField.classList.add('input-highlight')
    setTimeout(() => {
      inputField.classList.remove('input-highlight'); // remove the highlight class after 1 second
    }, 400);
  }

  function clickSettingsButton(event) {
    if (event.target.id === buttonRef.current.id) {
      setShowTimelineSettings(!showTimelineSettings);
    }
  }

  return (
    <div className='video-player-container'>
      <YouTube
        videoId={currentVod.id}
        containerClassName='iframe-vod'
        opts={{
          playerVars: {
            autoplay: 1,
            controls: 1,
            rel: 0,
            modestbranding: 1,
          },
        }}
        onReady={handlePlayerReady}
        onEnd={() => { }}
        onStateChange={handleStateChange}
        onSeek={handleSeeking}
        style={{ height: '95%', width: '100%' }}
      />
      <div className='vod-timeline-container-all'>
        <div className='currentTime'>{formattedCurrentTime()}</div>
        <div
          className='vod-timeline-container'
          onMouseMove={(event) => handleTimelineHover(event)}
          onMouseLeave={() => handleTimelineLeave(false)}
          onClick={(event) => handleTimelineClick(event)}
          style={{
            '--tooltip-position': `${tooltipPosition - 1}px`
          }}
          is-hovering={showTimelineLine}
        >
          {showTooltip && (
            <div
              className='vod-timeline-tooltip'
              style={{ left: `${tooltipPosition}px` }}
            >
              {formattedTooltipTime()}
            </div>
          )}
          <div className='vod-timeline' style={{ width: `${(currentTimelineTime / currentEncounterDuration) * 100}%` }}></div>
          <div className='vod-timeline-overlay'>
            {encounterEventsVisibility && !loadingLog && Object.keys(highlightTimestamps).map(eventType => {
              if (encounterEventsVisibility[eventType]) {
                const highlights = highlightTimestamps[eventType].map(timestamp => {
                  const position = (timestamp - (encounterEvents.startTime) / 1000) / currentEncounterDuration * 100;
                  const highlightStyle = {
                    left: `${position}%`,
                  };
                  return (
                    <div key={timestamp} className='vod-timeline-highlight' style={highlightStyle}>
                      {eventType == 'deaths' ?
                        <img src={deathIcon} className='vod-timeline-highlight-picture' alt="Death icon" />
                        : encounterEvents.events[eventType][0].icon && <img src={encounterEvents.events[eventType][0].icon} className='vod-timeline-highlight-picture' alt="Death icon" />}

                    </div>
                  );
                });
                return highlights;
              }
            })}
          </div>
        </div>
        <div className='vod-option' onClick={() => skipButton(-8.5)}>
          <img className='vod-option-icon' src={rewindIcon}></img>
        </div>
        <div className='vod-option' onClick={() => skipButton(11)}>
          <img className='vod-option-icon' src={forwardIcon}></img>
        </div>
        <div className='vod-option' >
          <img className='vod-option-icon' ref={buttonRef} id='vob1' onClick={((e) => clickSettingsButton(e))} src={settingsIcon}></img>
          {showTimelineSettings && (
            <TimelineSettingsDialog
              adjustOffsetConfirm={adjustOffsetConfirm}
              currentOffset={currentOffset}
              deathCutoff={deathCutoff}
              setDeathCutoff={setDeathCutoff}
              encounterEvents={encounterEvents}
              setEncounterEventsVisibility={setEncounterEventsVisibility}
              dialogRef={dialogRef}
              encounterEventsVisibility={encounterEventsVisibility}
              setEncounterEvents={setEncounterEvents}
              WCLToken={WCLToken}
              uniqueBossEvents={uniqueBossEvents}
              setUniqueBossEvents={setUniqueBossEvents}
              allBossEvents={allBossEvents}
              setAllBossEvents={setAllBossEvents}
            />
          )}
        </div>
      </div>
    </div>
  );

}
export default VideoPlayer;
