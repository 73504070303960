function Pull({ encounter, currentEncounter, goToTimeStamp, openLog, openLogIcon, formatDuration, formatTime }) {
    return (
      <div key={encounter.id} onClick={() => goToTimeStamp(encounter)}>
        <div className='encounter-container' style={{ border: currentEncounter != null && encounter.id == currentEncounter.id && '2px solid lightgreen' }}>
          <div className='encounter-container-left'>
            {encounter.kill ? (
              <>
                <div className='encounter-container-left-kill'>
                  KILL
                </div>
                <div className='encounter-container-left-checkmark'>
                  &#10003;
                </div>
              </>
            ) : (
              <>
                <div className='encounter-container-left-wipe-percentage'
                  style={{
                    color:
                      encounter.bossPercentage >= 75
                        ? '#666'
                        : encounter.bossPercentage >= 50
                          ? '#1eff00'
                          : encounter.bossPercentage >= 25
                            ? '#0070ff'
                            : encounter.bossPercentage >= 5
                              ? '#a335ee'
                              : encounter.bossPercentage >= 1
                                ? '#ff8000'
                                : 'rgb(20 20 20 / 66%)',
                  }}
                >
                  {Math.round(encounter.bossPercentage)}%
                </div>
                <div className='encounter-container-left-wipe'>
                  {`Phase ${!encounter.lastPhaseIsIntermission ? encounter.lastPhase + 1 : `i${encounter.lastPhase}`}`}
                </div>
              </>
            )}
          </div>
          <div className='encounter-container-right'>
            <div className='encounter-container-right-text-container'>
              <div className='encounter-container-right-text-pull-time' style={{ color: encounter.kill ? '#80e666' : '#e68066' }}>
                {encounter.pullCount} {`(${formatDuration(encounter.startTime, encounter.endTime)})`}
              </div>
              <div className='encounter-container-right-text-encounter-time'>
                {formatTime(encounter.startTime)}
              </div>
              <div className='encounter-container-right-text-encounter-link' onClick={(e) => openLog(encounter, e)}>
                <img src={openLogIcon} />
              </div>
            </div>
            <div className='pull-percentage' style={{ backgroundColor: encounter.kill && 'rgba(20, 20, 20, 0)' }}>
              <div className='pull-percentage-bar'
                style={{
                  backgroundColor:
                    encounter.bossPercentage >= 75
                      ? '#666'
                      : encounter.bossPercentage >= 50
                        ? '#1eff00'
                        : encounter.bossPercentage >= 25
                          ? '#0070ff'
                          : encounter.bossPercentage >= 5
                            ? '#a335ee'
                            : encounter.kill ? 'rgba(20, 20, 20, 0)'
                              : encounter.bossPercentage >= 1
                                ? '#ff8000'
                                : '#e268a8',
                  width: `${Math.round(100 - encounter.bossPercentage)}%`
                }}
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
  export default Pull;
