import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import loadingSpin from '../loadingspin.gif';
import colourLogo from '../rr-logo.png';
import { useState } from 'react';
import ConnectToDB from '../services/ConnectToDB';
import crossIcon from '../cross-icon.png'

function Login({
    setWCLToken, 
    setYTToken, 
    setLoginStatus, 
    setCurrentUserState, 
    setLoginStatusState,
    setShowLogin
}) {
    const [inputValues, setInputValues] = useState({});

    async function loginClick (){
        const status = await ConnectToDB.login(setWCLToken, setYTToken, inputValues, setCurrentUserState)
        console.log(status)
        if(status){
            sessionStorage.setItem('loggedIn', true)
            setLoginStatusState(true);
            setShowLogin(false);
        }
        else {
            console.log('wrong pw or username')
        }
        
    }

    const handleInputChange = (e, title) => {
        setInputValues({ ...inputValues, [title]: e.target.value });
    };

    return (
        <div className='frontpage-blurred'>
        <div className="login-messages">
            <img className='login-cross-icon' src={crossIcon} onClick={() => setShowLogin(false)}></img>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="username" placeholder="Username" onChange={(e) => handleInputChange(e, 'userName')}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => handleInputChange(e, 'password')}/>
                </Form.Group>
                <div className='loginButtons'>
                    <Button variant="primary" onClick={() => loginClick()}>
                        Login
                    </Button>
                    <Button variant="secondary" >
                        Register
                    </Button>
                </div>
            </Form>
        </div>
        </div>)
}
export default Login;