import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Dropdown from 'react-bootstrap/Dropdown';
import { HashLoader } from 'react-spinners';
import { useState, useEffect } from 'react';
import DialogBox from "../components/DialogBox";
import WCLLogo from "../warcraftlogs-image.png";
import refreshBtn from "../icons8-refresh-32.png";
import refreshLoadingSpinner from "../loading-loading-forever.gif";
import FetchYoutubeVideoData from '../services/FetchYoutubeVideoData';
import ConnectToDB from "../services/ConnectToDB";
import { ListGroup, ListGroupItem, Spinner } from "react-bootstrap";
import WCLData from "../services/WCLData";
import PullList from "../components/PullList";
import VideoPlayer from "../components/VideoPlayer";
import '../styles/Vods.css'

function Vods({ setLoading, WCLToken }) {
    const [vods, setVods] = useState();
    const [currentVod, setCurrentVod] = useState();
    const [currentVodName, setCurrentVodName] = useState();
    const [channels, setChannels] = useState();
    const [currentChannel, setCurrentChannel] = useState();
    const [addDialogVisible, setAddDialogVisible] = useState(false);
    const [removeDialogVisible, setRemoveDialogVisible] = useState(false);
    const [logs, setLogs] = useState();
    const [currentLog, setCurrentLog] = useState();
    const [currentTimestamp, setCurrentTimestamp] = useState(0);
    const [currentEncounterStartTime, setCurrentEncounterStartTime] = useState(0);
    const [currentEncounterDuration, setCurrentEncounterDuration] = useState(0);
    const [currentEncounter, setCurrentEncounter] = useState(null);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [encounterEvents, setEncounterEvents] = useState();
    const [deathCutoff, setDeathCutoff] = useState(3);
    const [loadingLog, setLoadingLog] = useState(false);
    const [uniqueBossEvents, setUniqueBossEvents] = useState();
    const [allBossEvents, setAllBossEvents] = useState();
    const [loadingLogsSpinner, setLoadingLogsSpinner] = useState(false);

    

    useEffect(() => {
        ConnectToDB.fetchChannels().then((fetchedChannels) => {
            var fcObj = fetchedChannels.reduce(function (acc, x) {
                for (var key in x) acc[key] = x[key];
                return acc;
            }, {});
            setChannels(fcObj);
        });
        if (WCLToken && !logs) {
            fetchAllLogs();
        }

    }, [])

    useEffect(() => {
        if(currentLog){
            var refreshedLog = logs.find(l => l.code == currentLog.code)
            setCurrentLog(refreshedLog);
        }
    }, [logs])

    async function refreshLogs(){
        setLoadingLogsSpinner(true);
        await fetchAllLogs();
    }

    function fetchAllLogs() {
        return new Promise((resolve, reject) => {
          WCLData.fetchLogs(WCLToken)
            .then(response => {
              setLogs(response);
              setLoadingLogsSpinner(false);
              resolve(); // Resolve the promise once logs are set
            })
            .catch(error => {
              // Handle any errors
              setLoadingLogsSpinner(false); // Make sure to handle error case as well
              reject(error);
            });
        });
      }


    function showAddDialog() {
        setAddDialogVisible(true);
    }
    function showRemoveDialog() {
        setRemoveDialogVisible(true);
    }
    async function selectVideo(video) {
        const videoId = video.id.videoId;
        setCurrentVodName(`${video.snippet.channelTitle} -  ${video.snippet.title}`)
        FetchYoutubeVideoData.fetchLiveStreamDetails(setCurrentVod, videoId);
    }
    function dialogDelete(channelToDelete) {
        var editedChannels = channels;
        ConnectToDB.removeChannel(channelToDelete);
        delete editedChannels[channelToDelete];
        setChannels({ ...editedChannels })
    }

    function selectChannel(channel, channelName) {
        setCurrentChannel(channelName)
        setCurrentVodName(`VOD`);
        FetchYoutubeVideoData.fetchVideosFromChannel(channels[channelName], setVods, setLoading)
    }

    function selectLog(selectedLog) {
        setCurrentLog(selectedLog)
    }

    function selectTimestamp(timeStampStart, timeStampEnd) {
        setCurrentTimestamp(timeStampStart);
        setCurrentEncounterStartTime(timeStampStart);
        setCurrentEncounterDuration((timeStampEnd - timeStampStart));
    }

    function getDate(dateString) {
        const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var dateMonth = new Date(dateString).getMonth()
        var dateDate = new Date(dateString).getDate()
        var dateDay = new Date(dateString).getDay()
        var formattedDate = `Streamed published on: ${month[dateMonth]} ${dateDate}`;

        return formattedDate
    }

    function dialogAccept(values) {
        var currentChannels = Object.keys(channels);
        var newChannel = [];
        if (!currentChannels.includes(values['Raider name'])) {
            newChannel = [[values["Raider name"], values["Channel ID"]]]

        }
        if (newChannel.length > 0) {
            newChannel = Object.fromEntries(newChannel);
            ConnectToDB.addChannel(newChannel).then((success) => {
                if (success) {
                    setChannels({ ...channels, ...newChannel });
                    setAddDialogVisible(false);
                }
            })
        }
    }

    function formatLogDate(startTime) {
        const weekdayList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const startTimeDate = new Date(startTime);
        var weekDay = startTimeDate.getDay() - 1;
        if (weekDay < 1) {
            weekDay = 6;
        }
        var day = startTimeDate.getDate();
        var month = startTimeDate.getMonth() + 1
        var year = startTimeDate.getFullYear()

        return `${weekdayList[weekDay]}, ${day}/${month}-${year}`
    }

    return (
        <div>
            {loadingLog && <div className='loading-log-info'><Spinner size={400} /></div>}
            <div className='container-vods'>
                {currentVod != null ?
                    <div className="iframe-vod">
                        <VideoPlayer
                            currentVod={currentVod}
                            currentTimestamp={currentTimestamp}
                            setCurrentTimestamp={setCurrentTimestamp}
                            currentEncounterDuration={currentEncounterDuration}
                            currentEncounterStartTime={currentEncounterStartTime}
                            setCurrentOffset={setCurrentOffset}
                            currentOffset={currentOffset}
                            setDeathCutoff={setDeathCutoff}
                            deathCutoff={deathCutoff}
                            encounterEvents={encounterEvents}
                            currentLog={currentLog}
                            setEncounterEvents={setEncounterEvents}
                            WCLToken={WCLToken}
                            uniqueBossEvents={uniqueBossEvents}
                            setUniqueBossEvents={setUniqueBossEvents}
                            allBossEvents={allBossEvents}
                            setAllBossEvents={setAllBossEvents}
                            currentEncounter={currentEncounter}
                            loadingLog={loadingLog}
                        />
                    </div>
                    :
                    <iframe className="iframe-vod"
                        src={`https://www.youtube.com/embed/nothinghere`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                }
                <div className='right-section-vods'>
                    <div className="right-section-dropdowns-container">
                        <div>
                            <div className="dropdown-vods">
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {currentChannel ? `Vod from: ${currentChannel}` : 'Channels'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        {channels != (undefined || null) ? Object.keys(channels).map((channelName, index) => <Dropdown.Item eventKey={index} onClick={() => selectChannel(channels, channelName)}>{channelName}</Dropdown.Item>) : <Dropdown.Item><div>Loading..</div></Dropdown.Item>}
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => showAddDialog()}>Add new channel</Dropdown.Item>
                                        <Dropdown.Item onClick={() => showRemoveDialog()}>Remove a channel</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {vods &&
                                <div className="dropdown-vods">
                                    <Dropdown className="vods-dropdown">
                                        <Dropdown.Toggle id="dropdown-basic" variant="success">
                                            {currentVod && currentVodName ? currentVodName : 'Select VoD'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            {vods.map(value => (
                                                <Dropdown.Item onClick={() => selectVideo(value)}>{value.snippet.channelTitle} -  {value.snippet.title}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                            <div className="dropdown-vods">
                                {currentVod &&
                                    <Dropdown className="channel-dropdown">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {currentLog ? (
                                                <>
                                                <div className="dropdown-toggle-with-image">
                                                    <img src={WCLLogo}/>
                                                    {`${formatLogDate(currentLog.startTime)}`}
                                                    
                                                </div>
                                                </>
                                            ) : (
                                                'Logs'
                                            )}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu >
                                            {logs != (null || undefined) ? logs.map((log, index) => <Dropdown.Item eventKey={index} onClick={() => selectLog(log)}>{`${log.owner.name} - ${formatLogDate(log.startTime)} `}</Dropdown.Item>) : <Dropdown.Item><div>Loading..</div></Dropdown.Item>}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }

                            </div>
                            {currentLog &&
                                
                                <div className="refreshBtn">
                                    {loadingLogsSpinner ? <img className="refreshLoadingSpinner" src={refreshLoadingSpinner}></img> : 
                                    <div>
                                        Refresh log
                                        <img className="refreshBtnIcon" onClick={() => refreshLogs()} src={refreshBtn}/>
                                    </div>}
                                    
                                </div>
                            
                                
                            }
                        </div>
                        {addDialogVisible && <DialogBox titles={["Raider name", "Role", "Channel ID"]} dialogVersion={"inputs"} setDialogVisible={setAddDialogVisible} dialogAccept={dialogAccept}></DialogBox>}
                        {removeDialogVisible && <DialogBox titles={channels} dialogVersion={"list"} setDialogVisible={setRemoveDialogVisible} dialogAccept={dialogAccept} dialogDelete={dialogDelete}></DialogBox>}

                    </div>
                    {currentLog && currentVod &&
                        <ListGroup>
                            <PullList
                                data={currentLog}
                                currentVod={currentVod}
                                selectTimestamp={selectTimestamp}
                                currentEncounter={currentEncounter}
                                setCurrentEncounter={setCurrentEncounter}
                                currentOffset={currentOffset}
                                WCLToken={WCLToken}
                                setEncounterEvents={setEncounterEvents}
                                deathCutoff={deathCutoff}
                                setLoadingLog={setLoadingLog}
                            />
                        </ListGroup>
                    }
                </div>
            </div>

        </div >

    )
}
export default Vods;