import Axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, setDoc, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore/lite';

const ConnectToDB = {
    openConnection: async function () {
        try {
            const firebaseConnection = initializeApp({
                apiKey: "AIzaSyCTaOmWAxb9fETjv3_sEB-LJt5BWpsFJnU",
                authDomain: "colour-bot-352216.firebaseapp.com",
                projectId: "colour-bot-352216",
                storageBucket: "colour-bot-352216.appspot.com",
                messagingSenderId: "392571174192",
                appId: "1:392571174192:web:fcf445ccd9016d7c119cf5",
                measurementId: "G-C2EHR65H42"
            });
            const db = getFirestore(firebaseConnection)
            return db
        } catch (error) {
            console.log(error);
            return null;
        }
    },
    fetchChannels: async function () {
        const db = await this.openConnection();
        const channelsCollection = collection(db, 'YoutubeChannels');
        const channelsSnapshot = await getDocs(channelsCollection);
        const channelList = channelsSnapshot.docs.map(doc => doc.data());
        return channelList
    },
    fetchDeclines: async function () {
        const db = await this.openConnection();
        const declinesCollection = collection(db, 'Declines');
        const declineSnapshot = await getDocs(declinesCollection);
        const declineList = declineSnapshot.docs.map(doc => doc.data());

        const discordNamesCollection = collection(db, 'DiscordNamesMapping');
        const discordNamesSnapshot = await getDocs(discordNamesCollection);
        const discordNamesList = discordNamesSnapshot.docs.map(doc => doc.data());
        return [discordNamesList[0], declineList]
    },
    addChannel: async function (channel) {
        const db = await this.openConnection();
        try{
            const docRef = await addDoc(collection(db, "YoutubeChannels"), channel);
            return true;
        }
        catch(e){
            console.log(e);
            return false;
        }

    },
    removeChannel: async function (channel) {
        const db = await this.openConnection();
        try{
            const channels = collection(db, 'YoutubeChannels');
            const allDocs = await getDocs(channels);
            const docRefs = allDocs.docs.map(doc => [doc.data(), doc.id]);

            let docToDelete = "";
            docRefs.forEach((document) => {
                Object.keys(document[0]).forEach((ch) => {
                    if(ch == channel){
                        docToDelete = document[1]
                    } 
                })
            })
            const docRef = doc(db, "YoutubeChannels", docToDelete);
            const docDelete = deleteDoc(docRef).then(() => {
                console.log(`Successfully deleted document with ID: ${docToDelete}`)
            })

        }
        catch(e){
            console.log(e);
            return false;
        }

    },
    login: async function(setWCLToken, setYTToken, loginInfo, setCurrentUserState){
        const db = await this.openConnection();
        try {
            const docRef = doc(db, "Users", loginInfo.userName);
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()){
                let data = docSnap.data();
                if(data.password != loginInfo.password){
                    return false;
                }
                else {
                    
                    sessionStorage.setItem('currentUser', loginInfo.userName)
                    setCurrentUserState(loginInfo.userName)
                    //GET Tokens from DB on Login to avoid fetching again

                    if(data.WCL_tokens){
                        setWCLToken(data.WCL_tokens.access_token);
                        sessionStorage.setItem('WCLToken', data.WCL_tokens.access_token);
                    }
                    if(data.YT_tokens){
                        setYTToken(data.YT_tokens.access_token);
                    }

                    //missing logic for expiration dates
                    return true;
                }
            }
            else {
                return false;
            }
        } catch (e) {
            console.log(e);
        }
    },
    saveToken: async function(type, currentUser, tokens){
        const db = await this.openConnection();
        try {
            const docRef = doc(db, 'Users', currentUser);
            await updateDoc(docRef, {
                [`${type}_tokens`]: tokens
            })
        } catch (e) {
            console.log(e);
        }
    }

}
export default ConnectToDB;
