import axios from 'axios';
// import Youtube from 'youtube-api';


const apiKey = 'AIzaSyCrGuS25rRF8pliD07IwYDF9ylUU_3DILY'

const FetchYoutubeVideoData = {
    //xP6ath6NiLs
    fetchVideosFromChannel: function (channel, setVods, setLoading) {
        axios.get(`https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channel}&part=snippet,id&order=date&maxResults=5`).then(result => {
            console.log(result)
            setVods(result.data.items)
            setLoading(false);
            return;
        }).catch((e) => {
            console.log(e)
        })


    },
    fetchLiveStreamDetails: function (setCurrentVod, videoId) {
        axios.get(`https://www.googleapis.com/youtube/v3/videos`, 
        {
            params: {
                part: 'liveStreamingDetails,status',
                id: videoId,
                key: apiKey
            }
        }).then(result => { 
            setCurrentVod(result.data.items[0]);
            return;
        }).catch(e => {
            console.log(e)
        })
    }
}

export default FetchYoutubeVideoData;