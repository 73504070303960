import axios from "axios"
const guildID = 10210
const limit = 10
const WCLData = {
  fetchLogs: async function (token) {
    let fetchedData;
    const query = `
        query ($guildID: Int!, $limit: Int!) {
            reportData {
              reports(guildID: $guildID, limit: $limit) {
                data {
                  code
                  fights(killType: Encounters) {
                    id
                    lastPhase
                    bossPercentage
                    name
                    startTime
                    endTime
                    kill
                    lastPhaseIsIntermission
                  }
                  endTime
                  startTime
                  owner {
                    name
                  }
                  title
                  zone {
                    name
                  }
                }
              }
            }
          }
        `;

    const variables = {
      guildID,
      limit,
    };

    await axios({
      url: 'https://www.warcraftlogs.com/api/v2/user',
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        query,
        variables,
      }),
    })
      .then((response) => {
        fetchedData = response.data.data.reportData.reports.data
      })
      .catch((error) => {
        console.error(error);
      });
    return fetchedData
  },
  fetchBasicEvents: async function (startTime, endTime, token, deathCutoff) {
    let fetchedData;

    const query = `
      query ($guildID: Int!, $limit: Int!, $startTime: Float!, $endTime: Float!) {
          reportData {
            reports(guildID: $guildID, limit: $limit) {
              data {
                events(startTime: $startTime, endTime: $endTime, dataType: Deaths){
                  data
                }
              }
            }
          }
        }
      `;

    const variables = {
      guildID,
      limit,
      startTime,
      endTime,
    };

    await axios({
      url: 'https://www.warcraftlogs.com/api/v2/user',
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        query,
        variables,
      }),
    })
      .then((response) => {
        const events = response.data.data.reportData.reports.data[0].events.data;
        const deathEvents = events.filter(event => event.type === 'death').slice(0, deathCutoff).map(event => {
          return {
            ...event,
            icon: 'deathIcon',
          };
        });
        const eventsObj = {
          startTime: startTime,
          endTime: endTime,
          events: {
            deaths: deathEvents,
          }
        };
        fetchedData = eventsObj;
      })
      .catch((error) => {
        console.log(error)
        console.error(error);
      });
    return fetchedData
  },

  fetchBossEvents: async function (startTime, endTime, token, encounterEvents, setEncounterEvents, events = []) {
    let query = `
    query ($guildID: Int!, $limit: Int!, $startTime: Float!, $endTime: Float!) {
      reportData {
        reports(guildID: $guildID, limit: $limit) {
          data {
            events(
              startTime: $startTime
              endTime: $endTime
              dataType: Casts
              hostilityType: Enemies
            ) {
              data
              nextPageTimestamp
            }
          }
        }
      }
    }
    
    `
    const variables = {
      guildID,
      limit,
      startTime,
      endTime,
    };

    return new Promise((resolve, reject) => {
      axios({
        url: 'https://www.warcraftlogs.com/api/v2/user',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          query,
          variables,
        }),
      }).then(async (response) => {
        let nextPageTimestamp = response.data.data.reportData.reports.data[0].events.nextPageTimestamp
        events.push(...response.data.data.reportData.reports.data[0].events.data);
        if (nextPageTimestamp) {
          const newEvents = await this.fetchBossEvents(nextPageTimestamp, endTime, token, encounterEvents, setEncounterEvents, events)
          resolve(newEvents);
        } else {
          const bossEvents = events.filter(event => event.type === 'cast' && event.sourceID > 0);
          const abilityIds = bossEvents.reduce((acc, obj) => {
            if (!acc.includes(obj.abilityGameID)) {
              acc.push(obj.abilityGameID);
            }
            return acc;
          }, []);
          const icons = await this.fetchIcons(abilityIds, token)
          const filteredBossEvents = bossEvents.map(obj => {
            const matchingAbility = icons.find(ability => ability.id === obj.abilityGameID);
            if (matchingAbility) {
              return {
                ...obj,
                name: matchingAbility.name,
                link: `https://www.wowhead.com/spell=${matchingAbility.id}`,
                icon: `https://wow.zamimg.com/images/wow/icons/large/${matchingAbility.icon}`
              };
            }
            return obj;
          });

          resolve(filteredBossEvents)
        }

      }).catch((error) => {
        console.log(error)
        console.error(error);
        reject(error);
      });
    });
  },


  fetchIcons: async function (abilityIds, token) {
    const query = `
        query {
          gameData{
          ${abilityIds.map((id, index) => `
            ability${index}: ability(id: ${id}) {
              id
              name
              icon
            }
          `).join('\n')}
        }
      }
      `;

    const variables = {};

    return axios({
      url: 'https://www.warcraftlogs.com/api/v2/user',
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        query,
        variables,
      }),
    }).then(response => {
      const icons = Object.values(response.data.data.gameData)
      return icons;
    }).catch(e => {
      console.error(e);
    });
  }
}
export default WCLData;