import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import ConnectToDB from "../services/ConnectToDB";
import '../App.css';

const DialogBox = ({ titles, setDialogVisible, dialogAccept, dialogVersion, dialogDelete }) => {
    const [inputValues, setInputValues] = useState({});

    const handleInputChange = (e, title) => {
        setInputValues({ ...inputValues, [title]: e.target.value });
    };

    const handleAcceptClick = () => {
        // fetch the values of the input fields
        dialogAccept(inputValues)
    }

    const handleCancelClick = () => {
        // clear the values of the input fields and close the dialog box
        setInputValues({});
        setDialogVisible(false);
    };
    const handleDelete = (e) => {
        dialogDelete(e.target.innerHTML);
    }

    return (
        <div style={styles.container}>
            {dialogVersion == 'list' &&
                <div>
                    <ListGroup>
                        {Object.keys(titles).map((item, index) => (
                            <ListGroup.Item action onClick={handleDelete}>{item}</ListGroup.Item>

                        ))}
                    </ListGroup>
                    <div style={styles.buttonContainer}>
                        <button style={{ ...styles.button, ...styles.acceptButton }} onClick={handleCancelClick}>
                            Done
                        </button>
                    </div>
                </div>
            }
            {dialogVersion == 'inputs' &&
                <div>
                    {titles.map((title) => (
                        <div key={title} style={styles.inputContainer}>
                            <label style={styles.inputTitle}>{title}</label>
                            <input
                                type="text"
                                value={inputValues[title] || ""}
                                onChange={(e) => handleInputChange(e, title)}
                                style={styles.input}
                            />
                        </div>
                    ))}
                    <div style={styles.buttonContainer}>
                        <button style={{ ...styles.button, ...styles.acceptButton }} onClick={handleAcceptClick}>
                            Accept
                        </button>
                        <button style={styles.button} onClick={handleCancelClick}>
                            Cancel
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: 20,
        borderRadius: 10,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        zIndex: 1,
        width: "40%",
        borderRadius: 2,
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 5,
        width: "100%"
    },
    inputTitle: {
        marginBottom: 5,
        fontWeight: "bold"
    },
    input: {
        padding: 2,
        borderRadius: 2,
        border: "1px solid gray",
        width: "90%",
        textAlign: "center"

    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 10,
    },
    button: {
        padding: 10,
        borderRadius: 5,
        border: "1px solid gray",
        cursor: "pointer",
    },
    acceptButton: {
        backgroundColor: "green",
        color: "white",
    },
    itemContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "10px 0"
    },
    itemValue: {
        flex: 1,
        textAlign: "left",
        fontSize: 18,
        marginRight: 20
    },
    deleteButton: {
        width: 30,
        height: 30,
        backgroundColor: "red",
        marginLeft: "auto"
    },
    deleteText: {
        color: "black",
        fontSize: 20
    }
};

export default DialogBox;
