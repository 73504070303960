import React, { useState, useEffect } from 'react';
import WCLData from '../services/WCLData';
import '../styles/PullList.css'
import Pull from './Pull'
import openLogIcon from '../open-log-icon.png';

const PullList = (
    {
        data,
        currentVod,
        selectTimestamp,
        currentEncounter,
        setCurrentEncounter,
        currentOffset,
        WCLToken,
        setEncounterEvents,
        deathCutoff,
        setLoadingLog
    }) => {

    useEffect(() => {
        if(currentEncounter){
            goToTimeStamp(currentEncounter);
        }
    }, [currentOffset])    

    const encounteredEncounters = {};
    const dataWithPullCount = data.fights.map(encounter => {
        if (!encounteredEncounters[encounter.encounterID]) {
            encounteredEncounters[encounter.encounterID] = 1;
            return {
                ...encounter,
                pullCount: 1,
            };
        }
        encounteredEncounters[encounter.encounterID] += 1;
        return {
            ...encounter,
            pullCount: encounteredEncounters[encounter.encounterID],
        };
    });
    const reportStart = data.startTime;
    const formatDuration = (startTime, endTime) => {
        const duration = (endTime - startTime) / 1000;
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const formatTime = (timeStamp) => {
        const date = new Date(reportStart + timeStamp);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12; // handle midnight case
        if (minutes < 10) minutes = `0${minutes}`;
        return `${hours}:${minutes}${amPm}`;
    }

    async function goToTimeStamp(encounter) {
        setCurrentEncounter(encounter);
        setLoadingLog(true);
        try {
            const eventsData = await WCLData.fetchBasicEvents(encounter.startTime, encounter.endTime, WCLToken, deathCutoff);
            setEncounterEvents(eventsData);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
        const vodTime = new Date(currentVod.liveStreamingDetails.actualStartTime);
        const encounterStartTime = new Date(reportStart + encounter.startTime);
        const encounterEndTime = new Date(reportStart + encounter.endTime);
        const timeStampStart = Math.round(((encounterStartTime - vodTime) / 1000) + 5 - currentOffset);
        const timeStampEnd = Math.round(((encounterEndTime - vodTime) / 1000) + 5 - currentOffset);
        setLoadingLog(false);
        selectTimestamp(timeStampStart, timeStampEnd);


    }

    function openLog(encounter, e) {
        e.stopPropagation();
        console.log(encounter);
        const url = `https://warcraftlogs.com/reports/${data.code}#fight=${encounter.id}`;
        window.open(url, '_blank');
    }

    const groupedEncounters = dataWithPullCount.reduce((acc, encounter) => {
        const groupIndex = acc.findIndex(group => group.name === encounter.name);
        if (groupIndex === -1) {
            acc.push({ name: encounter.name, encounters: [encounter] });
        } else {
            acc[groupIndex].encounters.push(encounter);
        }
        return acc;
    }, []);

    const [collapsedGroups, setCollapsedGroups] = useState([]);

    const toggleGroup = (groupName) => {
        if (collapsedGroups.includes(groupName)) {
            setCollapsedGroups(collapsedGroups.filter(name => name !== groupName));
        } else {
            setCollapsedGroups([...collapsedGroups, groupName]);
        }
    };

    return (
        <div className='pull-list'>
            {groupedEncounters.map(group => (
                <div key={group.name}>
                    <h6 className='encounter-container-encounter-name' onClick={() => toggleGroup(group.name)}>
                        {group.name}{!collapsedGroups.includes(group.name) ? <span className="triangle"></span> : <span className="triangle" style={{ transform: 'rotate(180deg)', color: 'red' }}></span>}
                    </h6>
                    {!collapsedGroups.includes(group.name) && group.encounters.map(encounter => (
                        <Pull
                            key={encounter.id}
                            encounter={encounter}
                            currentEncounter={currentEncounter}
                            goToTimeStamp={goToTimeStamp}
                            openLog={openLog}
                            formatDuration={formatDuration}
                            formatTime={formatTime}
                            openLogIcon={openLogIcon}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}
export default PullList;