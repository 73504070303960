import './App.css';
import * as Page from './pages/index'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, { useState, useEffect, useRef } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { HashLoader } from 'react-spinners';
import './warcraftlogs-image.png';
import logo from './rr-logo-small.png'
import Login from './components/Login'
import logoutIcon from './logout.png'

function App() {
  const [loading, setLoading] = useState(() => {
    return true;
  });

  var roster = new Array();
  useEffect(() => {
    setLoading(false);
    
    //setLoginUserAndTokenStatesAfterRedirects();
  }, [])


  const [currentTab, setCurrentTab] = useState({ 'Front Page': true, 'Review': false});
  const [tabs, setTabs] = useState(Object.keys(currentTab));
  const [WCLToken, setWCLToken] = useState();
  const [YTToken, setYTToken] = useState();
  const [loginStatusState, setLoginStatusState] = useState(false);
  const [currentUserState, setCurrentUserState] = useState();
  const [disableScroll, setDisableScroll] = useState(false);
  const [showLogin, setShowLogin] = useState(false);


  const changeTab = (tab) => {
    var tempCurrentTab = currentTab;
    tempCurrentTab[Object.keys(tempCurrentTab).filter(value => tempCurrentTab[value] == true).toString()] = false;
    tempCurrentTab[tab] = true;
    setCurrentTab({ ...tempCurrentTab });

  }

  const logout = () => {
    sessionStorage.setItem('loggedIn', false);
    sessionStorage.setItem('currentUser', '');
    const newTab = { ...currentTab };
    const currentActiveTab = Object.keys(newTab).find(tab => newTab[tab]);
    newTab[currentActiveTab] = false;
    newTab['Front Page'] = true;
    setCurrentTab(newTab);
    setCurrentUserState('')
    setLoginStatusState(false)

    sessionStorage.setItem('YTToken', '');
    sessionStorage.setItem('WCLToken', '');
    setYTToken('')
    setWCLToken('')
    window.history.replaceState({}, document.title, "http://localhost:3000");
  }
  function setLoginUserAndTokenStatesAfterRedirects() {
    setCurrentUserState(sessionStorage.getItem('currentUser'));
    setLoginStatusState(sessionStorage.getItem('loggedIn'));
    setYTToken(sessionStorage.getItem('YTToken'));
    setWCLToken(sessionStorage.getItem('WCLToken'));
  }
  useEffect(() => {
    if (currentTab['Bench']) {
      setDisableScroll(false);
      console.log('VODS')
    } else {
      setDisableScroll(true);
      console.log('NOT VODS');
    }
  }, [currentTab]);
  useEffect(() => {
    if (disableScroll) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [disableScroll]);

  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand>
          <img
            src={logo}
            width="34"
            height="34"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          /></Navbar.Brand>
        <Nav className="me-auto">
          {loginStatusState ? tabs.map((tab, index) => (
            <Nav.Link href={`#${tab}`} onClick={() => changeTab(tab)} tabIndex={index}>{tab}</Nav.Link>
          )) : <Nav.Link onClick={() => setShowLogin(true)}>Login</Nav.Link>}
        </Nav>
        {loginStatusState &&
          <div className='loggedInStatusContainer'>
            <h1 className='loggedInStatus'>{currentUserState}</h1>
            <img className='logoutBtn' onClick={() => logout()} src={logoutIcon} />
          </div>}
      </Navbar>
      {loading ? <div className='centerDiv'><HashLoader size={400} /></div> :
        currentTab['Front Page'] == true ?
          <Page.FrontPage 
          WCLToken={WCLToken} 
          setWCLToken={setWCLToken} 
          changeTab={changeTab} 
          YTToken={YTToken} 
          setYTToken={setYTToken} 
          setCurrentUserState={setCurrentUserState} 
          setLoginStatusState={setLoginStatusState}
          loginStatusState={loginStatusState}
          setShowLogin={setShowLogin}
          showLogin={showLogin}
          >
          </Page.FrontPage>
          :
        currentTab['Review'] == true &&
        <Page.Vods setLoading={setLoading} WCLToken={WCLToken} YTToken={YTToken}>
        </Page.Vods> }
    </div>
  );
}

export default App;
