import axios from 'axios';
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import wclLogoFrontPage from '../warcraftlogs-image.png';
import ytLogo from '../yt-logo.png';
import loadingSpin from '../loadingspin.gif';
import ConnectToDB from '../services/ConnectToDB';
import frontPageGif from '../frontpage-gif.gif';
import '../styles/FrontPage.css'
import logo from '../rr-logo-side.png';
import Login from '../components/Login'

function FrontPage({ 
    WCLToken, 
    setWCLToken, 
    changeTab, 
    YTToken, 
    setYTToken, 
    currentUser, 
    setLoginStatusState, 
    setCurrentUserState,
    loginStatusState,
    showLogin, 
    setShowLogin
}) {
    const [loadingWCL, setLoadingWCL] = useState(false);
    const [loadingYT, setLoadingYT] = useState(false);
    const [YTCode, setYTCode] = useState();


    useEffect(() => {
        let url = window.location.href;
        if (url.includes('auth/callback?code') && !WCLToken) {
            setLoadingWCL(true);
            const queryString = (new URLSearchParams(window.location.search));
            axios.get('http://localhost:5000/auth/callback/with-code', { params: { code: queryString.get('code') } }).then((r) => {
                var accessToken = r.data.access_token;
                if (!WCLToken && accessToken) {
                    ConnectToDB.saveToken('WCL', sessionStorage.getItem('currentUser'), r.data);
                    setWCLToken(accessToken);
                    sessionStorage.setItem('WCLToken', accessToken);
                    window.history.replaceState({}, document.title, "http://localhost:3000/#Front%20Page");
                    setLoadingWCL(false);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [WCLToken])

    const handleWCLClick = () => {
        var client_id = '986534e2-ae7e-433e-a8d2-96cd137df415';
        var redirect_uri = 'http://localhost:3000/auth/callback';
        var response_type = 'code';
        var state = encodeURIComponent('blob');
        window.location.href = `https://www.warcraftlogs.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
    };

    return (
        <div>
        <div className='frontpage'>
            <div className='frontpage-disclaimer-container'>
                <text className='frontpage-disclaimer-text'>
                    All data is retrieved from Warcraft Logs and Youtube. Item and ability tooltips by Wowhead. <br></br>
                    All images copyright Blizzard Entertainment. World of Warcraft Warcraft and Blizzard Entertainment are trademarks or registered trademarks of Blizzard Entertainment, Inc. in the U.S. and/or other countries.</text>
            </div>
            <div className='frontpage-background'>
                <div className='frontpage-messages'>
                    
                        <>
                            <div className='frontpage-row-container-left'>
                                <div clasName='frontpage-logo'>
                                    <img className='frontpage-logo-img' src={logo}></img>
                                </div>
                                <div className='frontpage-gif-container'>
                                    <img className='frontpage-gif' src={frontPageGif}></img>
                                </div>
                            </div>
                            <div className='frontpage-row-container-right'>
                                <div className='frontpage-text-container'>
                                    <h5 className='frontpage-text-header'>What is Raid Review?</h5>
                                    <p className='frontpage-text-area'>
                                        Raid Review lets you connect your <strong className='youtube-text-highlight'>Youtube livestream or VoD</strong> with your guilds <strong className='warcraftlogs-text-highlight'>Warcraftlogs</strong>.
                                        <br></br>
                                        The purpose of the tool is to make it easier to <strong className='rare-text-highlight'>review and analyze your guilds raid night</strong>, both during the raid but also after.
                                        <br></br>
                                        The tool supports <strong className='epic-text-highlight'>multiple points of view</strong> that you can change between, and makes a <strong className='legendary-text-highlight'>direct connection between the events of a pull in Warcraftlogs and the timestamp from your livestream/vod</strong>.
                                    </p>
                                </div>
                                <div className='frontpage-text-container'>
                                    <h5 className='frontpage-text-header'>Why YouTube?</h5>
                                    <p className='frontpage-text-area'><strong className='youtube-text-highlight'>YouTube VoDs live update</strong>, letting you analyze your pulls mid raid. <br></br>
                                        <strong className='twitch-text-highlight'>Twitch does not live update the VoD</strong>, leaving a major delay inbetween, making it impossible to instantly tab over after a pull and replay it. <br></br>
                                        It's possible Twitch vods with limited functionality will be added in the future, but for now there are no immediate plans for it. <br></br><br></br>
                                        If you're primarily streaming your raids on Twitch then I suggest <strong className='youtube-text-highlight'>multistreaming to YouTube at the same time</strong>. Their <strong className='epic-text-highlight'>vods stay up longer</strong>, are better quality and you're able to <strong className='legendary-text-highlight'>use this tool mid raid to analyze your wipes</strong>. </p>
                                </div>
                                <div class='frontpage-buttons'>
                                    <div class='frontpage-link-button discord'>
                                        <span class='icon'></span>
                                        <span class='text'>Discord</span>
                                    </div>
                                    <div class='frontpage-link-button patreon'>
                                        <span class='icon'></span>
                                        <span class='text'>Patreon</span>
                                    </div>
                                    {loginStatusState && WCLToken ? 
                                        <div className='frontpage-login-button warcraftlogs-authed' onClick={() => changeTab('Review')}>
                                            <span class='icon'></span>
                                            <span class='text'>Ready to go</span>
                                        </div>
                                    : loginStatusState && !WCLToken ? 
                                        <div className='frontpage-login-button warcraftlogs-no-auth'>
                                            <span class='icon'></span>
                                            <span class='text'>Auth to WCL</span>
                                        </div>
                                    : 
                                    <div class='frontpage-login-button' onClick={() => setShowLogin(true)}>Login</div>
                                    }
                                </div>
                            </div>
                        </>

                </div >

            </div>
        </div>
            {showLogin && <Login setWCLToken={setWCLToken} setYTToken={setYTToken} setCurrentUserState={setCurrentUserState} setLoginStatusState={setLoginStatusState} setShowLogin={setShowLogin}></Login>}
        </div>
    )
}
{/* <div className='loggedInFrontPageMessage'>
                        <div className='frontPageImages'>
                            <img src={wclLogoFrontPage} className='frontPageWCL' />
                        </div>
                        <h3>Logged in</h3>
                    </div> */}
export default FrontPage;
