import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { Dropdown } from "react-bootstrap";
import WCLData from "../services/WCLData";
import deathIcon from '../death-skull-icon.png';
import '../styles/TimelineSettingsDialog.css'

function TimelineSettingsDialog({
    adjustOffsetConfirm,
    currentOffset,
    deathCutoff,
    setDeathCutoff,
    encounterEvents,
    setEncounterEventsVisibility,
    dialogRef,
    encounterEventsVisibility,
    WCLToken,
    setEncounterEvents,
    uniqueBossEvents,
    setUniqueBossEvents,
    allBossEvents,
    setAllBossEvents,
    currentEncounter,
}) {

    const [currentTab, setCurrentTab] = useState('Offset');
    const [isLoadingBossEvents, setIsLoadingBossEvents] = useState();
    
    function onTabClick(tab) {
        document.getElementById(currentTab).style.borderBottomColor = '#555'

        setCurrentTab(tab.target.innerText);
        document.getElementById(tab.target.innerText).style.borderBottomColor = 'lightgreen'
    }
    function onCheckboxClick(key, checked) {
        setEncounterEventsVisibility((prevVisibility) => ({
            ...prevVisibility,
            [key]: checked
        }));
    }

    async function bossEvents() {
        setIsLoadingBossEvents(true);
        const events = await WCLData.fetchBossEvents(encounterEvents.startTime, encounterEvents.endTime, WCLToken, encounterEvents, setEncounterEvents);
        const uniqueEvents = events.reduce((acc, current) => {
            const existing = acc.find(obj => obj.abilityGameID === current.abilityGameID);
            if (!existing) {
                acc.push({
                    abilityGameID: current.abilityGameID,
                    name: current.name,
                    icon: current.icon,
                    link: current.link
                });
            }
            return acc;
        }, []);
        setAllBossEvents(events)
        setUniqueBossEvents(uniqueEvents);
        setIsLoadingBossEvents(false);

    }

    function addEncounterEvent(event){
        const matchingEvents = allBossEvents.filter(bossEvent => bossEvent.abilityGameID === event.abilityGameID);
        const newEncounterEvent = {
          [event.name]: matchingEvents
        };
      
        setEncounterEvents(prevEncounterEvents => ({
          ...prevEncounterEvents,
          events: {
            ...prevEncounterEvents.events,
            ...newEncounterEvent
          }
        }));
      }
      

    return (
        <div
            className='timeline-settings-dialog'
            ref={dialogRef}
        >
            <h3 className='timeline-settings-dialog-header'>Timeline settings</h3>
            <div className="timeline-settings-dialog-tabs">
                <h6 className="timeline-settings-dialog-tabs-tab-main" onClick={(e) => onTabClick(e)} id='Offset'>Offset</h6>
                <h6 className="timeline-settings-dialog-tabs-tab" onClick={(e) => onTabClick(e)} id='Events'>Events</h6>
            </div>
            {currentTab === 'Offset' ?
                <>
                    <h6 className='timeline-settings-dialog-information'>If the timeline, log time and ingame time isn't matching up, adjust the offset.</h6>
                    <div className='timeline-settings-dialog-offset-container'>
                        <p className='timeline-settings-dialog-offset-title'>{`Current offset (seconds):`}</p>
                        <input className='timeline-settings-dialog-offset-input' id='tsdoi1' defaultValue={currentOffset}></input>
                    </div>
                    <div className='timeline-settings-dialog-button-container'>
                        <button className='timeline-settings-dialog-button-confirm' onClick={() => adjustOffsetConfirm()}>Done</button>
                    </div>
                </>
                : currentTab === 'Events' && encounterEvents ?
                    <>
                        <h6 className='timeline-settings-dialog-information'>Check the boxes you want to show on the timeline.</h6>
                        <div className='timeline-settings-dialog-events-container-container'>
                            <text className="timeline-settings-dialog-event-header">Events</text>
                            {encounterEvents && Object.keys(encounterEvents.events).length > 0 && Object.keys(encounterEvents.events).map((key) => (
                                <>
                                    <div className="timeline-settings-dialog-event-container">
                                        {key == 'deaths' ? 
                                        <img className="dropdown-bossevent-icon-death" src={deathIcon}></img> : <img className="dropdown-bossevent-icon" src={encounterEvents.events[key][0].icon}></img>}
                                        <p className="timeline-settings-dialog-event">{key.slice(0, 1).toUpperCase() + key.slice(1)}</p>
                                        <div className="timeline-settings-dialog-event-event-input-container">
                                            <Form.Check
                                                type="checkbox"
                                                checked={encounterEventsVisibility && encounterEventsVisibility[key] ? encounterEventsVisibility[key] : false}
                                                onClick={(e) => onCheckboxClick(key, e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <Dropdown className="vods-dropdown" drop={'up'}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="dropdown-bossevent-button">
                                Add Boss Events
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-bossevent-menu">
                                {uniqueBossEvents && uniqueBossEvents.length > 0 ? 
                                    uniqueBossEvents.map((bossEvent, index) => 
                                        <Dropdown.Item 
                                        className='dropdown-bossevent-item' 
                                        eventKey={bossEvent}
                                        onClick={() => addEncounterEvent(bossEvent)}
                                        >
                                            <div>
                                                <img className="dropdown-bossevent-icon" src={bossEvent.icon}></img>
                                                <text className="dropdown-bossevent-icon-text">{bossEvent.name}</text>
                                            </div>
                                        </Dropdown.Item>) 
                                        : !isLoadingBossEvents ? bossEvents() : <Dropdown.Item className='dropdown-bossevent-item' >
                                            <div>Loading..</div>
                                            </Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </>

                    : currentTab === 'Events' && !encounterEvents && <h6 className='timeline-settings-dialog-information'>Select a fight before you start adding</h6>}
        </div>
    )
}
export default TimelineSettingsDialog;